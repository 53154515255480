<template>
  <div>
    <div>Please select a Role for the User</div>
    <b-table-simple responsive="xl" striped>
      <b-thead>
        <b-tr>
          <b-th />
          <b-th
            v-for="{key, value} in columns"
            :class="'top-control ' + key"
            :key="key"
          >
            <b-button
              :variant="isSelected(key) ? 'primary' : 'outline-primary'"
              @click="updateSelected(key)"
              :disabled="disabled"
            >
              {{ value }}
            </b-button>
          </b-th>
        </b-tr>
      </b-thead>
      <colgroup>
        <col />
        <col
          v-for="{key} in columns"
          :class="isSelected(key) ? 'selected' : ''"
          :key="key"
        />
      </colgroup>
      <b-tbody>
        <b-tr v-for="(row, index) in rows" :key="row.key">
          <b-td class="fun">
            <div class="d-flex align-items-center">
              <span>{{ row.label }}</span>
              <span v-if="row.view === 'ACCESS_VIEW'">
                <b-button
                  class="btn btn-xs btn-tip p-0 ml-1 mb-1"
                  variant="link"
                  :aria-label="row.label + ' Tip'"
                >
                  <b-icon
                    icon="info-circle"
                    variant="primary"
                    aria-hidden="true"
                    :id="`popoverAccessInfo_${index}`"
                  />
                  <b-popover
                    :target="`popoverAccessInfo_${index}`"
                    triggers="hover"
                    placement="right"
                  >
                    <div v-for="option in allLtd" :key="option.value">
                      <strong>{{ option.text }}</strong> - {{ option.description }}
                    </div>
                  </b-popover>
                </b-button>
              </span>
              <span v-else-if="row.view === 'SUBMIT_VIEW'">
                <b-button
                  class="btn btn-xs btn-tip p-0 ml-1 mb-1"
                  variant="link"
                  :aria-label="row.label + ' Tip'"
                >
                  <b-icon
                    icon="info-circle"
                    variant="primary"
                    aria-hidden="true"
                    :id="`popoverSubmitInfo_${index}`"
                  />
                  <b-popover
                    :target="`popoverSubmitInfo_${index}`"
                    triggers="hover"
                    placement="right"
                  >
                    <div v-for="option in yesNo" :key="option.value">
                      <strong>{{ option.text }}</strong> - {{ option.description }}
                    </div>
                  </b-popover>
                </b-button>
              </span>
            </div>
          </b-td>
          <b-td v-for="role in columns" :key="role.key" class="text-center">
            <template v-if="row.view === 'ACCESS_VIEW'">
              <span v-if="role.key === 'ADMIN'">All</span>
              <template v-else-if="hasRole(role.key, row)">
                <span v-if="!isActive(role.key)">All / Limited</span>
                <b-select
                  v-else
                  v-model="member.fullAccess"
                  :options="allLtd"
                  @change="$emit('change', member)"
                />
              </template>
              <check-icon :show="false" v-else />
            </template>
            <template v-else-if="row.view === 'SUBMIT_VIEW'">
              <template v-if="hasRole(role.key, row)">
                <span v-if="!isActive(role.key)">Optional</span>
                <b-select
                  v-else
                  v-model="member.canSubmit"
                  :options="yesNo"
                  @change="$emit('change', member)"
                />
              </template>
              <check-icon :show="false" v-else />
            </template>
            <check-icon v-else :show="hasRole(role.key, row)" />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CheckIcon from "../interface/CheckIcon";
import accessOptions from "../../../i18n/en/accessOptions";
import submitOptions from "../../../i18n/en/submitOptions";

export default {
  name: "RoleUpdate",
  props: ["orgId", "user", "disabled"],
  model: {
    prop: "user",
    event: "change",
  },
  components: {CheckIcon},
  computed: {
    ...mapState({
      orgs: state => state.role.orgs,
      allPermissions: state => state.role.allPermissions,
    }),
    ...mapGetters("role", {roles: "getRolesWithKey"}),
    customPermissions() {
      if (this.orgId) {
        const permissions = this.orgs[this.orgId];
        if (permissions !== undefined && permissions !== null) {
          return permissions;
        }
      }
      return [];
    },

    rows() {
      let custom = this.customPermissions;
      if (!custom || custom.length <= 0) {
        custom = [];
      }

      return this.allPermissions.map(perm => {
        const roles = [...perm.inRoles];
        if (custom.includes(perm.key)) {
          roles.push("CUSTOM");
        }
        return {
          key: perm.key,
          label: perm.label,
          inRoles: roles,
          view: perm.view,
        };
      });
    },
    columns() {
      const fields = this.roles.map(role => ({
        key: role.value,
        value: role.text,
      }));
      if (this.customPermissions.length === 0) {
        const index = fields.findIndex(r => r.key === "CUSTOM");
        if (index >= 0) {
          fields.splice(index, 1);
        }
      }
      return fields;
    },
  },
  data() {
    return {
      member: {
        ...this.user,
      },
      allLtd: accessOptions,
      yesNo: submitOptions,
    };
  },
  methods: {
    ...mapActions("role", ["getCustomPermissions"]),
    hasRole(role, row) {
      return row.inRoles.includes(role);
    },
    isSelected(column) {
      return this.user && this.user.group === column;
    },
    isActive(role) {
      return this.isSelected(role) && !this.disabled;
    },
    updateSelected(role) {
      this.member.group = role;
      // Reset the selected values upon each change to the minimum permissions
      // Set fullAccess to false unless it's admin
      this.member.fullAccess = role === "ADMIN";
      // Always set submit to false
      this.member.canSubmit = false;
      this.$emit("change", this.member);
    },
  },
  mounted() {
    this.getCustomPermissions(this.orgId).catch(error => {
      console.log("Error when requesting the custom permissions: " + error);
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../../content/scss/bootstrap-variables";
td.fun {
  font-weight: 600;
  background-color: $primary_alpha_10;
}

col.selected {
  border: 1px solid $success;
  background-color: $success_alpha_10;
}

.top-control {
  width: 9.5em;
}

.READER {
  width: 11.5em;
}

.top-control .btn {
  width: 100%;
}

dl {
  display: grid;
  grid-template-columns: 2em 1fr;
  margin-bottom: 0;
}

.btn.btn-xs {
  font-size: x-small;
}

.btn.btn-tip:focus {
  box-shadow: none;
}

</style>
